import Resource from 'components/global/Resource'
import { Button, LanguageToggle } from '@arckit/ui'
import { Col, Row, Section } from 'components/grid'
import Layout from 'components/Layout'
import JourneyOfABook from 'components/libraries/JourneyOfABook2'
import SubNav from 'components/SubNav'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React, { useState } from 'react'
import { H1, H2, H3 } from 'components/typography/Headings'
import { P } from 'components/typography/Paragraph'
import GetStartedCTA from 'components/forms/GetStartedCTA'
import SectionTitle from 'components/global/SectionTitle'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { Carousel } from 'react-responsive-carousel'
import Modal from 'components/global/Modal'
import OverlayVideo from 'components/overlay-video'

interface UnitItems {
	unit1: Item[]
	unit2: Item[]
	unit3: Item[]
	unit4: Item[]
}
interface Item {
	id: number
	title: string
	description: string
	image: string
}

const items: UnitItems = {
	unit1: [
		{
			id: 1,
			title: 'Read-Aloud Immersion Collection',
			description: 'High quality literature and informational read alouds build students background knowledge and vocabulary on the major concepts taught in the unit.',
			image: '/images/arc-core/baskets2.png'
		},
		{
			id: 2,
			title: '100 Book Challenge Rotating Classroom Libraries',
			description:
				'Hand-curated by educators, these collections are balanced to include fiction (including best-selling series) and nonfiction across multiple genres and themes. The collections come leveled, organized, and ready to read. Each classroom receives a unique set of Wide Reading baskets, which circulate among participating classrooms to create a continuous stream of up to 15,000 titles.',
			image: '/images/arc-core/baskets3.png'
		},
		{
			id: 3,
			title: 'Take-Home Book Bags',
			description: 'Students use these durable book bags to transport all their 100 Book Challenge materials to and from school.',
			image: 'https://images.ctfassets.net/xo7k5gkhtsb4/7swUznuvnCtYg4zYAyg3Fh/1606ad85c889b830006e4139d682e342/year-long-book-bags.png'
		},
		{
			id: 4,
			title: 'IRLA Home-Practice Cards',
			description:
				'Tailored to the needs of students at each level of reading acquisition and color-coded to the standards-based leveling system, IRLA Home-Practice Cards help students and parents identify a student’s level and practice the appropriate reading strategies for that level.',
			image: 'https://images.ctfassets.net/xo7k5gkhtsb4/fQ4Nn12ewADwwpaBcqdZm/cfba6d021d80e2f4b19b58fec98fba3c/IRLA_Skills_Cards_-_New_Rendering.png'
		},
		{
			id: 5,
			title: 'Word Study & Writer’s Notebooks',
			description:
				'Writer’s Notebooks provide space for daily beginning reading and writing practice. Notebooks include blank writing and drawing pages to support inquiry, engagement, and developing literacy skills.',
			image: 'https://images.ctfassets.net/xo7k5gkhtsb4/8S3vZe4kT9mja5d6uv42B/a67a8b97fd3b0340f82e827546bd9c83/Notebooks-Unit_1-Gr1-ENG.png'
		},
		{
			id: 6,
			title: 'Teacher Guide',
			description:
				'In the first Unit of ARC Core, students will fall in love with reading through books that are too good to resist, a community of peers who read and talk about books together, and a teacher who believes in them and helps them develop both reading passions and reading skills.',
			image: 'https://images.ctfassets.net/xo7k5gkhtsb4/6ALfA1j0XvZr60Pj2cViW7/c1ffd0eb684fa948f6e37baf100a8d8a/ARCC-LLE11-GR1.png'
		},
		{
			id: 7,
			title: 'Foundational Skills Toolkit',
			description:
				'The IRLA Toolkits and Foundational Skills Toolkits enable teachers to provide differentiated, targeted, and efficient skills instruction embedded into one-on-one conferences and strategy plans.',
			image: 'https://images.ctfassets.net/xo7k5gkhtsb4/1g7P7H841HskP34QOwwZps/814221e6ea61d9771ede298d50e0878d/FSTK_Handbook___Basket___1B.png'
		},
		{
			id: 8,
			title: 'Equipped for Reading Success',
			description: 'Literacy Lab modules for Grades K and 1 include an additional professional resource for the development of phonological awareness skills.',
			image: 'https://images.ctfassets.net/xo7k5gkhtsb4/1SdbuDGaEJabQJ6THd6uRB/a412199a1ef94c47c11a3d4ae9b271d3/Equipped_for_Reading_Success.png'
		}
	],
	unit2: [],
	unit3: [],
	unit4: []
}

export default function ARCCorePage() {
	const [language, setLanguage] = useState<'english' | 'spanish'>('english')
	const [isOpen, setIsOpen] = useState(false)
	const [item, setItem] = useState<Item | null>(null)

	function goToCta() {
		const input = document.getElementById('get-started-cta-email')
		input?.focus()
	}

	function handleModal(item: Item) {
		setIsOpen(true)
		setItem(item)
	}

	function ItemTextButton({ item }: { item: Item }) {
		return (
			<button onClick={() => handleModal(item)}>
				<span className="font-semibold text-ab-100">{item.title}</span>
			</button>
		)
	}

	function ItemImageButton({ item }: { item: Item }) {
		return (
			<div className="relative max-w-max rounded bg-white p-3">
				<button onClick={() => handleModal(item)}>
					<img className="h-28" src={item.image} alt={item.title} />
				</button>
			</div>
		)
	}

	return (
		<Layout pageName="ARC Core">
			<Section className="overflow-hidden pt-10 pb-0">
				<div className="absolute top-0 h-full w-full bg-white">
					<div className="rainbow-gradient-light absolute right-0 bottom-0 top-auto h-[250px] w-[250px] rounded-[30%_70%_71%_29%_/_30%_30%_70%_70%] opacity-50 blur-[60px] sm:block md:bottom-auto md:top-8 md:h-[500px] md:w-[500px]"></div>
					<div className="rainbow-gradient-light absolute left-0 right-auto -top-[110px] hidden h-[300px] w-[300px] -translate-x-[50%] translate-y-[20%] rounded-full opacity-50 blur-[80px] sm:block"></div>
				</div>

				<Row middle className="relative pt-10 pb-10">
					<div className="mx-auto max-w-3xl text-center">
						<H1>
							<div className="mx-auto mb-6 w-4/5">
								<StaticImage src="../../static/images/logos/arc-core-logo.png" alt="arc core logo" />
							</div>
						</H1>
						<H2 className="mb-4">Your Ultimate Core Curriculum</H2>
						<P>
							Grounded in the science of reading, and with foundational skills and knowledge-building at its heart, ARC Core<sup>®</sup> meets the English and Spanish
							literacy needs of all students in any learning environment.
						</P>
						<div className="mt-10 flex items-center justify-center gap-6">
							<Button onClick={goToCta} size="xl">
								Get Started
							</Button>
							<button className="flex items-center gap-2">
								<span className="text-gray-500">Watch It in Action</span>
								<div className="flex h-8 w-8 items-center justify-center rounded-full border bg-ab-100/10">
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-4 w-4 text-ab-100">
										<path
											fillRule="evenodd"
											d="M4.5 5.653c0-1.426 1.529-2.33 2.779-1.643l11.54 6.348c1.295.712 1.295 2.573 0 3.285L7.28 19.991c-1.25.687-2.779-.217-2.779-1.643V5.653z"
											clipRule="evenodd"
										/>
									</svg>
								</div>
							</button>
						</div>
					</div>
					{/* <Col width="w-full md:w-1/2">
						<H1 className="mb-4">
							Your{' '}
							<span className="relative leading-relaxed text-ab-100 after:absolute after:-bottom-8 after:left-0 after:h-8 after:w-full after:rounded-[50%] after:border-[5px] after:border-transparent after:border-t-core-y sm:leading-normal">
								All-In-One
							</span>{' '}
							Core Curriculum
						</H1>
						<P className="mb-8">
							Meticulously crafted to empower educators and inspire students. Our comprehensive approach transforms classrooms into dynamic learning environments,
							ensuring mastery in core subjects for every learner.
						</P>
						<div className="mb-8 flex items-center gap-6">
							<Button onClick={goToCta} size="xl">
								Start Your Journey
							</Button>
						</div>
					</Col>
					<Col width="w-1/2" className="hidden md:block">
						<StaticImage loading="eager" src="../../static/images/libraries/hero.png" alt="girl holding a stack of books over her head" />
					</Col> */}
				</Row>
				<Row>
					<Col width="w-1/4">
						<StaticImage src="../../static/images/arc-core/3.png" alt="student" />
					</Col>
					<Col width="w-1/4" className="pt-20">
						<StaticImage src="../../static/images/arc-core/2.png" alt="student" />
					</Col>
					<Col width="w-1/4" className="pt-20">
						<StaticImage src="../../static/images/arc-core/1.png" alt="student" />
					</Col>
					<Col width="w-1/4">
						<StaticImage src="../../static/images/arc-core/4.png" alt="student" />
					</Col>
				</Row>
			</Section>
			<Section margin="mt-32">
				<SectionTitle title="What is ARC Core?" />
				<Row>
					<Col width="w-3/4" className="mx-auto mb-10">
						<div className="text-center">
							<P>
								ARC Core is a K-12 English & Spanish literacy curriculum that is built on the science of reading. It combines explicit, systematic, whole-group
								foundational skills instruction with diverse and engaging classroom libraries that build student background knowledge and vocabulary while fostering
								a lifelong love of reading.
							</P>
						</div>
					</Col>
					<Col width="w-3/4" className="mx-auto">
						<OverlayVideo title="ARC Core Overview" />
					</Col>
				</Row>
			</Section>
			<Section margin="mt-32">
				<SectionTitle title="How Does ARC Core Work?" />
				<Row>
					<Col width="w-3/4" className="mx-auto">
						<div className="text-center">
							<P>
								ARC Core is comprised of four engaging topic-based units each year. Students acquire science, social studies, and literary genre knowledge through
								daily reading of quality books, dialogue with other students, and writing. Let’s look at a 1st grade class as an example. We’ll review what
								materials are included and consider how they are used to develop literacy. If you want a more detailed look at what’s included in each unit, you can{' '}
								<Link to="/arc-core">view the components.</Link>.
							</P>
						</div>
					</Col>
				</Row>
			</Section>
			<Section className="py-10">
				<div>
					<Row>
						<Col width="w-3/4" className="mx-auto mb-10">
							<div className="mt-10 rounded-lg border shadow-lg">
								<div className="p-6">
									<div className="rounded-t-lg p-10 text-center">
										<span className="font-inter text-8xl font-extrabold text-ab-100">Unit 1</span>
									</div>
									<div className="mb-8 text-center">
										<H2>The Literacy Lab</H2>
										<P className="pb-8 pt-4">
											The resources for this unit are the basis for ARC Core implementation and are designed to be used for the entire year. In first grade,
											unit 1 is based on the theme of “Mapping My World.”
										</P>
									</div>
									<Tabs selectedTabClassName="text-ab-100 font-semibold">
										<TabList className="mb-6 flex flex-wrap gap-4 px-4">
											<Tab className="mb-6 min-w-max flex-1 cursor-pointer rounded border px-3 py-5 text-center text-base lg:text-xl">For Students</Tab>
											<Tab className="mb-6 min-w-max flex-1 cursor-pointer rounded border px-3 py-5 text-center text-base lg:text-xl">For Teachers</Tab>
										</TabList>
										<TabPanel>
											<span className="mb-8 block text-center text-2xl font-semibold">Foundational Skills, Volume of Reading, and Practice</span>
											<P className="text-left">
												Students will build their reading stamina, background knowledge, and vocabulary by reading (or having a teacher read aloud) a{' '}
												<ItemTextButton item={items.unit1[0]} /> and texts available in the <ItemTextButton item={items.unit1[1]} />
											</P>
											<div className="mt-6 mb-12 rounded-lg bg-gray-200 p-6">
												<div className="flex justify-center gap-4">
													<ItemImageButton item={items.unit1[0]} />
													<ItemImageButton item={items.unit1[1]} />
												</div>
											</div>
											<P className="text-left">
												Students can practice reading at home with family support by using <ItemTextButton item={items.unit1[2]} /> and{' '}
												<ItemTextButton item={items.unit1[3]} />
											</P>
											<div className="mt-6 mb-12 rounded-lg bg-gray-200 p-6">
												<div className="flex justify-center gap-4">
													<ItemImageButton item={items.unit1[2]} />
													<ItemImageButton item={items.unit1[3]} />
												</div>
											</div>
											<span className="mb-8 block text-center text-2xl font-semibold">Writing</span>
											<P className="text-left">
												Students will use <ItemTextButton item={items.unit1[4]} /> to develop their writing, inquiry, and literacy skills. The notebooks can
												also serve as a daily deliverable to assess growth.
											</P>
											<div className="mt-6 rounded-lg bg-gray-200 p-6">
												<div className="flex justify-center gap-4">
													<ItemImageButton item={items.unit1[4]} />
												</div>
											</div>
										</TabPanel>
										<TabPanel>
											<span className="mb-8 block text-center text-2xl font-semibold">Instruction & Intervention</span>
											<P className="text-left">
												Teachers will receive an <ItemTextButton item={items.unit1[5]} /> that provides daily guidance on best practices. It follows the
												theme of the unit, in this case, “Mapping My World.” A <ItemTextButton item={items.unit1[6]} /> will provide skill-building
												opportunities for small groups or students who need additional support to reach grade level. Teachers will also receive a
												professional resource such as "<ItemTextButton item={items.unit1[7]} />" by Dr. David Kilpatrick to assist their phonological skills
												instruction.
											</P>
											<div className="mt-10 rounded-lg bg-gray-200 p-6">
												<div className="flex justify-center gap-4">
													<ItemImageButton item={items.unit1[5]} />
													<ItemImageButton item={items.unit1[6]} />
													<ItemImageButton item={items.unit1[7]} />
												</div>
											</div>
										</TabPanel>
									</Tabs>
								</div>
							</div>
						</Col>
					</Row>
				</div>
				<div className="flex justify-center">
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-8 w-8 text-gray-300">
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
						/>
					</svg>
				</div>
				<div>
					<Row>
						<Col width="w-3/4" className="mx-auto mb-10">
							<div className="mt-10 rounded-lg border shadow-lg">
								<div className="p-6">
									<div className="rounded-t-lg p-10 text-center">
										<span className="font-inter text-8xl font-extrabold text-ab-100">Unit 2</span>
									</div>
									<div className="mb-8 text-center">
										<H2>Informational Reading</H2>
										<P className="pb-8 pt-4">
											Unit 2: Informational Reading Every unit is centered on a theme. In first grade, you might select “Wild and Endangered Animals,” but
											there are <Link to="/themes">other themes</Link> to choose from. The students build knowledge and skill as they work towards a final
											project at the end of the unit.
										</P>
									</div>
									<Tabs selectedTabClassName="text-ab-100 font-semibold">
										<TabList className="mb-6 flex flex-wrap gap-4 px-4">
											<Tab className="mb-6 min-w-max flex-1 cursor-pointer rounded border px-3 py-5 text-center text-base lg:text-xl">For Students</Tab>
											<Tab className="mb-6 min-w-max flex-1 cursor-pointer rounded border px-3 py-5 text-center text-base lg:text-xl">For Teachers</Tab>
										</TabList>
										<TabPanel>
											<P className="text-left">
												Students will get access to a new <span className="font-semibold text-ab-100">Read-Aloud Collection</span> and a{' '}
												<span className="font-semibold text-ab-100">Research Library</span> that are aligned to the Wild and Endangered Animals theme.
											</P>
											<div className="mt-6 mb-12 rounded-lg bg-gray-200 p-6">
												<div className="flex justify-center gap-4">
													<div className="relative max-w-max rounded bg-white p-3">
														<button onClick={() => setIsOpen(true)}>
															<img
																className="h-28"
																src="https://images.ctfassets.net/xo7k5gkhtsb4/3aE5pPueo49qAwfslJKwEp/8c2b58e291c99bd1eeb41b620eea1454/wild-and-endangered-animals.png"
																alt=""
															/>
														</button>
													</div>
													<div className="relative max-w-max rounded bg-white p-3">
														<button onClick={() => setIsOpen(true)}>
															<img className="h-28" src="/images/arc-core/baskets.png" alt="" />
														</button>
													</div>
												</div>
											</div>
											<P className="text-left">
												Students will use <span className="font-semibold text-ab-100">Research Cards</span>,{' '}
												<span className="font-semibold text-ab-100">Word Study & Lab Notebooks</span>,{' '}
												<span className="font-semibold text-ab-100">Writing Cards</span>, and a{' '}
												<span className="font-semibold text-ab-100">Final Project Organizer</span> to work toward a research project at the end of the unit.
											</P>
											<div className="mt-6 rounded-lg bg-gray-200 p-6">
												<div className="flex justify-center gap-4">
													<div className="relative max-w-max rounded bg-white p-3">
														<img
															className="h-28"
															src="https://images.ctfassets.net/xo7k5gkhtsb4/184C9s7XUQjep1kAKviNIZ/17f09580c5a11dae2db26f10476f38e2/wild-and-endangered-animals.png"
															alt=""
														/>
													</div>
													<div className="relative max-w-max rounded bg-white p-3">
														<img
															className="h-28"
															src="https://images.ctfassets.net/xo7k5gkhtsb4/6dAFTgDmVpbhjZ11mYUCco/fdfc599492cb55f7c940d4997afae375/g1_u2_english_animals.png"
															alt=""
														/>
													</div>
													<div className="relative max-w-max rounded bg-white p-3">
														<img
															className="h-28"
															src="https://images.ctfassets.net/xo7k5gkhtsb4/5ClxMvYG7jg9d1QAIDMW03/c066ddbd984c80d17cfeba31cb4ef5ad/Writing_Cards_-_Informational.png"
															alt=""
														/>
													</div>
													<div className="relative max-w-max rounded bg-white p-3">
														<img
															className="h-28"
															src="https://images.ctfassets.net/xo7k5gkhtsb4/38HVz14110wzlyyhGCJV48/fd6d7bff5c7bd81ffc7fdf481d6f43bc/wild-and-endangered-animals.png"
															alt=""
														/>
													</div>
												</div>
											</div>
										</TabPanel>
										<TabPanel>
											<P className="text-left">
												Teachers will receive a thematic <span className="font-semibold text-ab-100">Teacher Guide</span> that provides daily instructional
												support. All of the resources from the Literacy Lab will continue to help teachers build student literacy.
											</P>
											<div className="mt-10 rounded-lg bg-gray-200 p-6">
												<div className="flex justify-center gap-4">
													<div className="relative max-w-max rounded bg-white p-3">
														<img
															className="h-28"
															src="https://images.ctfassets.net/xo7k5gkhtsb4/58362b2XYEDTthkX3DV9qM/e9224d32090091b7d9c09fef65152f1f/wild-and-endangered-animals.png"
															alt=""
														/>
													</div>
												</div>
											</div>
										</TabPanel>
									</Tabs>
								</div>
							</div>
						</Col>
					</Row>
				</div>
				<div className="flex justify-center">
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-8 w-8 text-gray-300">
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
						/>
					</svg>
				</div>
				<div>
					<Row>
						<Col width="w-3/4" className="mx-auto mb-10">
							<div className="mt-10 rounded-lg border shadow-lg">
								<div className="p-6">
									<div className="rounded-t-lg p-10 text-center">
										<span className="font-inter text-8xl font-extrabold text-ab-100">Unit 3</span>
									</div>
									<div className="mb-8 text-center">
										<H2>Literature Reading</H2>
										<P className="pb-8 pt-4">
											In this unit, students study the literary elements of a genre, for example “Family Stories.” The included materials are once again
											centralized around that theme. Students will learn what a genre is, while also learning how to write a text in that genre.
										</P>
									</div>
									<Tabs selectedTabClassName="text-ab-100 font-semibold">
										<TabList className="mb-6 flex flex-wrap gap-4 px-4">
											<Tab className="mb-6 min-w-max flex-1 cursor-pointer rounded border px-3 py-5 text-center text-base lg:text-xl">For Students</Tab>
											<Tab className="mb-6 min-w-max flex-1 cursor-pointer rounded border px-3 py-5 text-center text-base lg:text-xl">For Teachers</Tab>
										</TabList>
										<TabPanel>
											<P className="text-left">
												How can you learn about genres if you don’t have books? Building on the structure established in the previous units, students will
												have access to dozens of great examples with a new <span className="font-semibold text-ab-100">Read-Aloud Collection</span>, an{' '}
												<span className="font-semibold text-ab-100">Informational Text Set</span>, and a{' '}
												<span className="font-semibold text-ab-100">Genre Library</span>.
											</P>
											<div className="mt-6 mb-12 rounded-lg bg-gray-200 p-6">
												<div className="flex justify-center gap-4">
													<div className="relative max-w-max rounded bg-white p-3">
														<button onClick={() => setIsOpen(true)}>
															<img
																className="h-28"
																src="https://images.ctfassets.net/xo7k5gkhtsb4/6flx3uXsWtZMsbmPlfcuuM/b76177e4f3b7931eec7fbca6c3776518/family-stories.png"
																alt=""
															/>
														</button>
													</div>
													<div className="relative max-w-max rounded bg-white p-3">
														<button onClick={() => setIsOpen(true)}>
															<img
																className="h-28"
																src="https://images.ctfassets.net/xo7k5gkhtsb4/7wf7123i5GshjuY6vtITDs/45691fecd87a75d0129f2adf111128d2/8.png"
																alt=""
															/>
														</button>
													</div>
													<div className="relative max-w-max rounded bg-white p-3">
														<button onClick={() => setIsOpen(true)}>
															<img className="h-28" src="/images/arc-core/baskets4.png" alt="" />
														</button>
													</div>
												</div>
											</div>
											<P className="text-left">
												They’ll also use <span className="font-semibold text-ab-100">Word Study & Lab Notebooks</span>,{' '}
												<span className="font-semibold text-ab-100">Genre Cards</span>, and to build their foundational literacy skills as they move through
												the On-Ramp to Reading.
											</P>
											<div className="mt-6 rounded-lg bg-gray-200 p-6">
												<div className="flex justify-center gap-4">
													<div className="relative max-w-max rounded bg-white p-3">
														<img
															className="h-28"
															src="https://images.ctfassets.net/xo7k5gkhtsb4/4fNcoQPFPLfNDtMXxOkWbG/24b2925df8e94b037de568a31e101da9/g1_u3_english_family_stories.png"
															alt=""
														/>
													</div>
													<div className="relative max-w-max rounded bg-white p-3">
														<img
															className="h-28"
															src="https://images.ctfassets.net/xo7k5gkhtsb4/5qvDsfNLLJQhzUUkllkiaL/fe244d6037945eb1ef7c25fc778c92b5/family-stories.png"
															alt=""
														/>
													</div>
												</div>
											</div>
										</TabPanel>
										<TabPanel>
											<P className="text-left">
												Teachers will receive a new <span className="font-semibold text-ab-100">Teacher Guide</span> and a text from esteemed educator and
												author <span className="font-semibold text-ab-100">Dr. Jeffrey Wilhelm</span>. In this case, the text might be Literary Elements,
												which will give teachers inspiration and research-based practices in teaching the intricacies of genre.
											</P>
											<div className="mt-10 mb-12 rounded-lg bg-gray-200 p-6">
												<div className="flex justify-center gap-4">
													<div className="relative max-w-max rounded bg-white p-3">
														<img
															className="h-28"
															src="https://images.ctfassets.net/xo7k5gkhtsb4/56SaBDKCbJnfApl8iiV3yk/9563a7458d973e338c7a798feaf4fb39/family-stories.png"
															alt=""
														/>
													</div>
													<div className="relative max-w-max rounded bg-white p-3">
														<img
															className="h-28"
															src="https://images.ctfassets.net/xo7k5gkhtsb4/01FfRcZ13Mhh68VKMNHSGk/fc11d65333aa8f8295b8bf26b7210399/jeffrey-wilhelm-unit-3.png"
															alt=""
														/>
													</div>
												</div>
											</div>
											<P>
												Don’t forget, teachers will still have all the supports from Unit 1, including the IRLA, SchoolPace, SchoolPace Connect, and IRLA
												Toolkits.
											</P>
										</TabPanel>
									</Tabs>
								</div>
							</div>
						</Col>
					</Row>
				</div>
				<div className="flex justify-center">
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-8 w-8 text-gray-300">
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
						/>
					</svg>
				</div>
				<div>
					<Row>
						<Col width="w-3/4" className="mx-auto mb-10">
							<div className="mt-10 rounded-lg border shadow-lg">
								<div className="p-6">
									<div className="rounded-t-lg p-10 text-center">
										<span className="font-inter text-8xl font-extrabold text-ab-100">Unit 4</span>
									</div>
									<div className="mb-8 text-center">
										<H2>Argument Writing</H2>
										<P className="pb-8 pt-4">
											The goal of this unit is to teach students how to conduct research, and then hone that research into a compelling argument that they can
											debate in class. Don’t be surprised if the debates get a little ...energized!
										</P>
									</div>
									<Tabs selectedTabClassName="text-ab-100 font-semibold">
										<TabList className="mb-6 flex flex-wrap gap-4 px-4">
											<Tab className="mb-6 min-w-max flex-1 cursor-pointer rounded border px-3 py-5 text-center text-base lg:text-xl">For Students</Tab>
											<Tab className="mb-6 min-w-max flex-1 cursor-pointer rounded border px-3 py-5 text-center text-base lg:text-xl">For Teachers</Tab>
										</TabList>
										<TabPanel>
											<P className="text-left">
												New unit, new books! Each class will get a new <span className="font-semibold text-ab-100">Read-Aloud Collection</span> and a{' '}
												<span className="font-semibold text-ab-100">Research Library</span> aligned to the theme, for example “Plants.”
											</P>
											<div className="mt-6 mb-12 rounded-lg bg-gray-200 p-6">
												<div className="flex justify-center gap-4">
													<div className="relative max-w-max rounded bg-white p-3">
														<button onClick={() => setIsOpen(true)}>
															<img
																className="h-28"
																src="https://images.ctfassets.net/xo7k5gkhtsb4/SfOy6QdtwIM3vBIzgL9wx/5a69212d18766262bfc9c340183f10fd/Plantas_1st_Grade_Read_Alouds_-_Basket_1.png"
																alt=""
															/>
														</button>
													</div>

													<div className="relative max-w-max rounded bg-white p-3">
														<button onClick={() => setIsOpen(true)}>
															<img className="h-28" src="/images/arc-core/baskets5.png" alt="" />
														</button>
													</div>
												</div>
											</div>
											<P className="text-left">
												They’ll use <span className="font-semibold text-ab-100">Word Study & Lab Notebooks</span>,{' '}
												<span className="font-semibold text-ab-100">Research Cards</span>, <span className="font-semibold text-ab-100">Writing Cards</span>,
												and <span className="font-semibold text-ab-100">Final Project Organizers</span> to construct their argument while continuing their
												literacy growth.
											</P>
											<div className="mt-6 rounded-lg bg-gray-200 p-6">
												<div className="flex justify-center gap-4">
													<div className="relative max-w-max rounded bg-white p-3">
														<img
															className="h-28"
															src="https://images.ctfassets.net/xo7k5gkhtsb4/5MF5xoRLkRDG8EW7bCuczI/39f3462186649b6950d00064f0a86e4d/g1_u4_english_plants.png"
															alt=""
														/>
													</div>
													<div className="relative max-w-max rounded bg-white p-3">
														<img
															className="h-28"
															src="https://images.ctfassets.net/xo7k5gkhtsb4/XFT7YhXul62JhERlzD2vC/43d061cf350917e06e9a6c36643f7cb2/plants.png"
															alt=""
														/>
													</div>
													<div className="relative max-w-max rounded bg-white p-3">
														<img
															className="h-28"
															src="https://images.ctfassets.net/xo7k5gkhtsb4/9TV12S0H8p12HVk8Zib4W/abe8d62401c677bdf9b9b42ebd246d62/Writing_Cards_-_Argument.png"
															alt=""
														/>
													</div>
													<div className="relative max-w-max rounded bg-white p-3">
														<img
															className="h-28"
															src="https://images.ctfassets.net/xo7k5gkhtsb4/5n4vhvA3WVwPfUKQdCLe6w/e905ef9afa5de0651f962bc6253f34d5/plants.png"
															alt=""
														/>
													</div>
												</div>
											</div>
										</TabPanel>
										<TabPanel>
											<P className="text-left">
												A new <span className="font-semibold text-ab-100">Teacher Guide</span> will give teachers guidance and inspiration as they drive
												toward the class argument at the conclusion of the unit. Instructors will also receive a new text from Dr. Wilhelm, in this case{' '}
												<span className="font-semibold text-ab-100">“Oh Yeah?! Putting Argument to Work Both in School and Out.”</span>
											</P>
											<div className="mt-10 mb-12 rounded-lg bg-gray-200 p-6">
												<div className="flex justify-center gap-4">
													<div className="relative max-w-max rounded bg-white p-3">
														<img
															className="h-28"
															src="https://images.ctfassets.net/xo7k5gkhtsb4/2Ql5jgmzo6rDJ4Z4ldNC3I/9f6bdb61cb3c7367b5c0934e3e4aba85/plants.png"
															alt=""
														/>
													</div>
													<div className="relative max-w-max rounded bg-white p-3">
														<img
															className="h-28"
															src="https://images.ctfassets.net/xo7k5gkhtsb4/6snA7IXGuhhSBHH25kE9yu/8f3584227fad99501cff081775493e6f/jeffrey-wilhelm-unit-4.png"
															alt=""
														/>
													</div>
												</div>
											</div>
										</TabPanel>
									</Tabs>
								</div>
							</div>
						</Col>
					</Row>
				</div>
			</Section>
			<Section margin="mt-20 md:mt-40">
				<Row middle overflow={false}>
					<Col width="w-11/12 sm:w-3/5 xl:w-2/5" className="relative z-0 mx-auto mb-8">
						<GetStartedCTA
							pageName="ARC Core Page"
							subHeading="Ready. Set. Read."
							heading="Your Ultimate Core Curriculum"
							underline="Ultimate"
							copy="Choose from dozens of content-rich themes your students will love."
						/>
						<span className="absolute -bottom-6 -left-6 z-[-1] hidden sm:inline">
							<svg width="107" height="134" viewBox="0 0 107 134" fill="none" xmlns="http://www.w3.org/2000/svg">
								<circle cx="104.999" cy="132" r="1.66667" transform="rotate(180 104.999 132)" fill="#d1d5db" />
								<circle cx="104.999" cy="117.333" r="1.66667" transform="rotate(180 104.999 117.333)" fill="#d1d5db" />
								<circle cx="104.999" cy="102.667" r="1.66667" transform="rotate(180 104.999 102.667)" fill="#d1d5db" />
								<circle cx="104.999" cy="88" r="1.66667" transform="rotate(180 104.999 88)" fill="#d1d5db" />
								<circle cx="104.999" cy="73.3333" r="1.66667" transform="rotate(180 104.999 73.3333)" fill="#d1d5db" />
								<circle cx="104.999" cy="45" r="1.66667" transform="rotate(180 104.999 45)" fill="#d1d5db" />
								<circle cx="104.999" cy="16" r="1.66667" transform="rotate(180 104.999 16)" fill="#d1d5db" />
								<circle cx="104.999" cy="59" r="1.66667" transform="rotate(180 104.999 59)" fill="#d1d5db" />
								<circle cx="104.999" cy="30.6666" r="1.66667" transform="rotate(180 104.999 30.6666)" fill="#d1d5db" />
								<circle cx="104.999" cy="1.66665" r="1.66667" transform="rotate(180 104.999 1.66665)" fill="#d1d5db" />
								<circle cx="90.3333" cy="132" r="1.66667" transform="rotate(180 90.3333 132)" fill="#d1d5db" />
								<circle cx="90.3333" cy="117.333" r="1.66667" transform="rotate(180 90.3333 117.333)" fill="#d1d5db" />
								<circle cx="90.3333" cy="102.667" r="1.66667" transform="rotate(180 90.3333 102.667)" fill="#d1d5db" />
								<circle cx="90.3333" cy="88" r="1.66667" transform="rotate(180 90.3333 88)" fill="#d1d5db" />
								<circle cx="90.3333" cy="73.3333" r="1.66667" transform="rotate(180 90.3333 73.3333)" fill="#d1d5db" />
								<circle cx="90.3333" cy="45" r="1.66667" transform="rotate(180 90.3333 45)" fill="#d1d5db" />
								<circle cx="90.3333" cy="16" r="1.66667" transform="rotate(180 90.3333 16)" fill="#d1d5db" />
								<circle cx="90.3333" cy="59" r="1.66667" transform="rotate(180 90.3333 59)" fill="#d1d5db" />
								<circle cx="90.3333" cy="30.6666" r="1.66667" transform="rotate(180 90.3333 30.6666)" fill="#d1d5db" />
								<circle cx="90.3333" cy="1.66665" r="1.66667" transform="rotate(180 90.3333 1.66665)" fill="#d1d5db" />
								<circle cx="75.6654" cy="132" r="1.66667" transform="rotate(180 75.6654 132)" fill="#d1d5db" />
								<circle cx="31.9993" cy="132" r="1.66667" transform="rotate(180 31.9993 132)" fill="#d1d5db" />
								<circle cx="75.6654" cy="117.333" r="1.66667" transform="rotate(180 75.6654 117.333)" fill="#d1d5db" />
								<circle cx="31.9993" cy="117.333" r="1.66667" transform="rotate(180 31.9993 117.333)" fill="#d1d5db" />
								<circle cx="75.6654" cy="102.667" r="1.66667" transform="rotate(180 75.6654 102.667)" fill="#d1d5db" />
								<circle cx="31.9993" cy="102.667" r="1.66667" transform="rotate(180 31.9993 102.667)" fill="#d1d5db" />
								<circle cx="75.6654" cy="88" r="1.66667" transform="rotate(180 75.6654 88)" fill="#d1d5db" />
								<circle cx="31.9993" cy="88" r="1.66667" transform="rotate(180 31.9993 88)" fill="#d1d5db" />
								<circle cx="75.6654" cy="73.3333" r="1.66667" transform="rotate(180 75.6654 73.3333)" fill="#d1d5db" />
								<circle cx="31.9993" cy="73.3333" r="1.66667" transform="rotate(180 31.9993 73.3333)" fill="#d1d5db" />
								<circle cx="75.6654" cy="45" r="1.66667" transform="rotate(180 75.6654 45)" fill="#d1d5db" />
								<circle cx="31.9993" cy="45" r="1.66667" transform="rotate(180 31.9993 45)" fill="#d1d5db" />
								<circle cx="75.6654" cy="16" r="1.66667" transform="rotate(180 75.6654 16)" fill="#d1d5db" />
								<circle cx="31.9993" cy="16" r="1.66667" transform="rotate(180 31.9993 16)" fill="#d1d5db" />
								<circle cx="75.6654" cy="59" r="1.66667" transform="rotate(180 75.6654 59)" fill="#d1d5db" />
								<circle cx="31.9993" cy="59" r="1.66667" transform="rotate(180 31.9993 59)" fill="#d1d5db" />
								<circle cx="75.6654" cy="30.6666" r="1.66667" transform="rotate(180 75.6654 30.6666)" fill="#d1d5db" />
								<circle cx="31.9993" cy="30.6666" r="1.66667" transform="rotate(180 31.9993 30.6666)" fill="#d1d5db" />
								<circle cx="75.6654" cy="1.66665" r="1.66667" transform="rotate(180 75.6654 1.66665)" fill="#d1d5db" />
								<circle cx="31.9993" cy="1.66665" r="1.66667" transform="rotate(180 31.9993 1.66665)" fill="#d1d5db" />
								<circle cx="60.9993" cy="132" r="1.66667" transform="rotate(180 60.9993 132)" fill="#d1d5db" />
								<circle cx="17.3333" cy="132" r="1.66667" transform="rotate(180 17.3333 132)" fill="#d1d5db" />
								<circle cx="60.9993" cy="117.333" r="1.66667" transform="rotate(180 60.9993 117.333)" fill="#d1d5db" />
								<circle cx="17.3333" cy="117.333" r="1.66667" transform="rotate(180 17.3333 117.333)" fill="#d1d5db" />
								<circle cx="60.9993" cy="102.667" r="1.66667" transform="rotate(180 60.9993 102.667)" fill="#d1d5db" />
								<circle cx="17.3333" cy="102.667" r="1.66667" transform="rotate(180 17.3333 102.667)" fill="#d1d5db" />
								<circle cx="60.9993" cy="88" r="1.66667" transform="rotate(180 60.9993 88)" fill="#d1d5db" />
								<circle cx="17.3333" cy="88" r="1.66667" transform="rotate(180 17.3333 88)" fill="#d1d5db" />
								<circle cx="60.9993" cy="73.3333" r="1.66667" transform="rotate(180 60.9993 73.3333)" fill="#d1d5db" />
								<circle cx="17.3333" cy="73.3333" r="1.66667" transform="rotate(180 17.3333 73.3333)" fill="#d1d5db" />
								<circle cx="60.9993" cy="45" r="1.66667" transform="rotate(180 60.9993 45)" fill="#d1d5db" />
								<circle cx="17.3333" cy="45" r="1.66667" transform="rotate(180 17.3333 45)" fill="#d1d5db" />
								<circle cx="60.9993" cy="16" r="1.66667" transform="rotate(180 60.9993 16)" fill="#d1d5db" />
								<circle cx="17.3333" cy="16" r="1.66667" transform="rotate(180 17.3333 16)" fill="#d1d5db" />
								<circle cx="60.9993" cy="59" r="1.66667" transform="rotate(180 60.9993 59)" fill="#d1d5db" />
								<circle cx="17.3333" cy="59" r="1.66667" transform="rotate(180 17.3333 59)" fill="#d1d5db" />
								<circle cx="60.9993" cy="30.6666" r="1.66667" transform="rotate(180 60.9993 30.6666)" fill="#d1d5db" />
								<circle cx="17.3333" cy="30.6666" r="1.66667" transform="rotate(180 17.3333 30.6666)" fill="#d1d5db" />
								<circle cx="60.9993" cy="1.66665" r="1.66667" transform="rotate(180 60.9993 1.66665)" fill="#d1d5db" />
								<circle cx="17.3333" cy="1.66665" r="1.66667" transform="rotate(180 17.3333 1.66665)" fill="#d1d5db" />
								<circle cx="46.3333" cy="132" r="1.66667" transform="rotate(180 46.3333 132)" fill="#d1d5db" />
								<circle cx="2.66536" cy="132" r="1.66667" transform="rotate(180 2.66536 132)" fill="#d1d5db" />
								<circle cx="46.3333" cy="117.333" r="1.66667" transform="rotate(180 46.3333 117.333)" fill="#d1d5db" />
								<circle cx="2.66536" cy="117.333" r="1.66667" transform="rotate(180 2.66536 117.333)" fill="#d1d5db" />
								<circle cx="46.3333" cy="102.667" r="1.66667" transform="rotate(180 46.3333 102.667)" fill="#d1d5db" />
								<circle cx="2.66536" cy="102.667" r="1.66667" transform="rotate(180 2.66536 102.667)" fill="#d1d5db" />
								<circle cx="46.3333" cy="88" r="1.66667" transform="rotate(180 46.3333 88)" fill="#d1d5db" />
								<circle cx="2.66536" cy="88" r="1.66667" transform="rotate(180 2.66536 88)" fill="#d1d5db" />
								<circle cx="46.3333" cy="73.3333" r="1.66667" transform="rotate(180 46.3333 73.3333)" fill="#d1d5db" />
								<circle cx="2.66536" cy="73.3333" r="1.66667" transform="rotate(180 2.66536 73.3333)" fill="#d1d5db" />
								<circle cx="46.3333" cy="45" r="1.66667" transform="rotate(180 46.3333 45)" fill="#d1d5db" />
								<circle cx="2.66536" cy="45" r="1.66667" transform="rotate(180 2.66536 45)" fill="#d1d5db" />
								<circle cx="46.3333" cy="16" r="1.66667" transform="rotate(180 46.3333 16)" fill="#d1d5db" />
								<circle cx="2.66536" cy="16" r="1.66667" transform="rotate(180 2.66536 16)" fill="#d1d5db" />
								<circle cx="46.3333" cy="59" r="1.66667" transform="rotate(180 46.3333 59)" fill="#d1d5db" />
								<circle cx="2.66536" cy="59" r="1.66667" transform="rotate(180 2.66536 59)" fill="#d1d5db" />
								<circle cx="46.3333" cy="30.6666" r="1.66667" transform="rotate(180 46.3333 30.6666)" fill="#d1d5db" />
								<circle cx="2.66536" cy="30.6666" r="1.66667" transform="rotate(180 2.66536 30.6666)" fill="#d1d5db" />
								<circle cx="46.3333" cy="1.66665" r="1.66667" transform="rotate(180 46.3333 1.66665)" fill="#d1d5db" />
								<circle cx="2.66536" cy="1.66665" r="1.66667" transform="rotate(180 2.66536 1.66665)" fill="#d1d5db" />
							</svg>
						</span>
						<span className="absolute -top-6 -right-6 z-[-1] hidden sm:inline">
							<svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fillRule="evenodd" clipRule="evenodd" d="M0 100C0 44.7715 0 0 0 0C55.2285 0 100 44.7715 100 100C100 100 100 100 0 100Z" fill="#0271BC"></path>
							</svg>
						</span>
					</Col>
				</Row>
			</Section>
			<Modal title={item ? item.title : ''} isOpen={isOpen} wide handleClose={() => setIsOpen(false)}>
				<P>{item?.description}</P>
				<img src={item ? item.image : ''} alt={item ? item.title : ''} className="mx-auto" />
			</Modal>
		</Layout>
	)
}

interface CollectionCard {
	collection: {
		id: string
		name: string
		text: string
		image: string
		link?: string
		doc?: string
		externalLink?: string
	}
}

function CollectionCard({ collection }: CollectionCard) {
	return (
		<div id={collection.id} className="h-full rounded-b-lg border shadow">
			<div className="bg-slate-100 p-8">
				<img src={collection.image} alt="hook books" />
			</div>
			<div className="p-6">
				<span className="mb-3 block text-3xl font-semibold">{collection.name}</span>
				<P size="md" className="mb-3">
					{collection.text}
				</P>
				{collection.link && (
					<Link to={collection.link}>
						<Button>Learn More</Button>
					</Link>
				)}
				{collection.doc && (
					<Resource title={collection.doc} linkOnly>
						<Button>Learn More</Button>
					</Resource>
				)}
				{collection.externalLink && (
					<a href={collection.externalLink} target="_blank">
						<Button>Learn More</Button>
					</a>
				)}
			</div>
		</div>
	)
}
